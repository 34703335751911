import logo from './logo.svg';
import './App.css';
import VideoPlayer from './Live';

function App() {
  return (
    <div className="">
     <VideoPlayer />
    </div>
  );
}

export default App;
